import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SocialIcon } from 'react-social-icons';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();
  const [isAlertVisible, setAlertVisible] = useState(true);
  return (
    <div
      id="contact"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          24/7
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Get In Touch With Us
        </Typography>

        <Box
          mt={2}
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SocialIcon url="https://github.com/Mariner-Digital-Agency" style={{ height: 25, width: 25 }} />
          <SocialIcon url="https://www.linkedin.com/company/18124893/" style={{ height: 25, width: 25 }} />
        </Box>

        <Formik
          initialValues={{
            email: '',
            name: '',
            message: ''
          // policy: true
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().max(50).required('Required'),
            name: Yup.string().max(50).required('Required'),
            message: Yup.string().max(255).required('Required')
          // policy: Yup.boolean().oneOf([true], 'This field must be checked')
          })}
          onSubmit={async (values, {
            resetForm,
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            // console.log(values);

            const DTO = {
              to: 'matthew@marinerdigitalagency.com',
              from: 'matthew@marinerdigitalagency.com',
              subject: `👻 Contact form request from ${values.name} <${values.email}>`,
              text: values.message,
              html: values.message,
            };

            console.log(DTO);


            // Example POST method implementation:
            async function postData(url = '', data = {}) {
              // Default options are marked with *
              const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json'
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
              });
              return response.json(); // parses JSON response into native JavaScript objects
            }

            setSubmitting(true);


            postData('https://cors-proxy-mda.herokuapp.com/' + 'https://us-central1-mdamailer313.cloudfunctions.net/app/emailMessage', DTO)
              .then((data) => {
                console.log(data);
                // Reset
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                alert('Message Sent');
              }).catch((error) => {
              // Handle error
                console.log(error);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                alert('Error try again later');
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <Card>
              <Divider />
              <CardContent>
                {isAlertVisible && (
                <Box mb={3}>
                  <Alert
                    onClose={() => setAlertVisible(false)}
                    severity="info"
                  >
                    Please allow 24-72 hours for inquiries
                  </Alert>
                </Box>
                )}
                {isSubmitting ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    my={5}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Full Name"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          label="Email Address"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2}>

                      <TextField
                        error={Boolean(touched.message && errors.message)}
                        fullWidth
                        helperText={touched.message && errors.message}
                        label="Short Message"
                        multiline
                        name="message"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.message}
                        variant="outlined"
                      />
                    </Box>
                    <Box mt={2} />
                    {Boolean(touched.policy && errors.policy) && (
                    <FormHelperText error>
                      {errors.policy}
                    </FormHelperText>
                    )}
                    <Box mt={2}>
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Send Message
                      </Button>
                    </Box>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>

      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
