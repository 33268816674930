import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Our Services
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Not just a pretty face
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  01
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Cutting Edge Designs
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    First impressions are important.
                    Just like your brick-and-mortar storefront, it’s good to update the aesthetics of your site occasionally.
                    Trendy, clean websites catch your customer’s eye and make them want to explore further.
                    <br /><br />
                    Here at MDA, we are at the cutting edge of technology and design trends.
                    We do brand research and by identifying the key values of the consumer, we are able to establish a strong foundation to achieve the brand goals and execute the brand vision in a manner of excellence.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  02
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Flawless Functionality
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                      As experts of our craft – we maximize the value of your online operations and get ahead of your competitors.
                      With an absolute focus on detail, our creative process is supported by an iterative, adaptable, and measured approach.
                      We create maintainable, scalable and responsive solutions catered for your business.
                      <br /><br />
    Whether it be a custom software solution, payment integration or a lead-generation funnel - we want you to benefit from our technological knowledge and business expertise.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  03
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Post-Launch Analytics
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Using our experience in the industry we deliver scalable, maintainable, reusable and efficient solutions to your problems.
                    After the initial launch, we use a plethora of modern tools to monitor and keep you updated on statistics/data on all your fronts.
                    <br />     <br />
                    Whether through our integrations with your existing marketing platform or through our state of the art AI - you will be able to find, measure and capitalize on all trends post launch – <i>no headaches</i>.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
