const axios = require("axios");
const MockAdapter = require("axios-mock-adapter");

// This sets the mock adapter on the default instance
const mock = new MockAdapter(axios);
const sgMail = require('@sendgrid/mail');
sgMail.setApiKey('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');


mock.onPost('/api/sendMail').reply((data) => {
  const name = data.inquirerName
  const email = data.email
  const message = data.messageText

  const msg = {
      from: `${name}👻 <${email}>`, // sender address
      to: "matthew@marinerdigitalagency.com", // list of receivers
      subject: "Contact form request ✔", // Subject line
      text: message, // plain text body
      html: message, // html body
  };


  // //ES6
  // sgMail
  //   .send(msg)
  //   .then(() => {}, error => {
  //     console.error(error);
  //
  //     if (error.response) {
  //       console.error(error.response.body)
  //     }
  //   });
//ES8
  (async () => {
    try {
      await sgMail.send(msg);
      return [200]
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.error(error.response.body)
      }
      return [500];
    }
  })();
});


